@import "~@/assets/scss/global.scss";










































































































































































































































.el-input {
    max-width: 240px;
}

.el-form-item {
    margin-bottom: 10px !important;
}
