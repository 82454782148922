@import "~@/assets/scss/global.scss";






































































































































































































































































































































::v-deep {
    .el-radio-button {
        width: 25%;

        .el-radio-button__inner {
            width: 100%;
        }
    }

    .el-form-item__error {
        display: contents;
    }

    .el-table__row {
        font-size: 14px;

        .mark {
            &>div {
                color: #409EFF !important;
            }
        }

        &.is-deleted {
            color: red;
            text-decoration: line-through;

            .cell {
                color: #b5b5b5;
            }
        }

        .el-table__cell {
            padding: 5px 0;
            vertical-align: middle;
        }
    }

    .el-tag {
        font-size: 14px;
        min-height: 30px;
        height: auto;
        line-height: 30px;
        margin: 2px !important;

        vertical-align: middle;
    }

    .el-select {
        width: 120px;
    }
}

.input-new-name {
    width: 100px;

    ::v-deep {
        .el-input__inner {
            height: 35px !important;
            padding-left: 5px;
        }
    }
}

.button-new-name {
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
}
