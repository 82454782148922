@import "~@/assets/scss/global.scss";






























































































































































.editForm {
    border: 1px solid #ebeef5;
    padding-top: 10px;
    margin-bottom: 10px;
}

.operation {
    border: 1px solid #ebeef5;
    padding: 5px;
    margin-bottom: 10px;

    >div {
        display: inline-block;
        margin: 0 10px;
    }
}
