@import "~@/assets/scss/global.scss";


































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-aside {
    .el-col {
      .el-switch {
        margin-top: 5px;
      }

      .el-range-editor {
        width: 190px;
        padding: 3px 5px;

        .el-range__close-icon {
          width: 5px;
        }
      }

      .el-select {
        width: 90px;
        margin-right: 5px;
      }

      .el-input {
        width: 90px;
      }
    }

    .el-footer {
      font-size: 15px;
      font-weight: bold;
      padding-top: 8px;

      span {
        display: inline-block;
        width: 20%;
      }
    }
  }

  #print-view {
    .el-main {
      position: relative;

      .print-view-main {
        width: 100%;
        height: 100%;
        background: #a3a3a3;
        position: relative;
        box-sizing: border-box;
        overflow: auto;

        .paper-wrapper {
          min-height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 10px;
        }
      }

      .print-view-left {
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 99;

        >div {
          background: white;
          margin-bottom: 5px;
          height: 32px;
          width: 65px;
          line-height: 32px;
          text-align: center;
          border: 1px solid white;
          border-left: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: pointer;

          &.is-active {
            color: #409eff;
            border-color: #409eff;
          }
        }
      }
    }

    .el-footer {
      text-align: right;
      line-height: 40px;
      vertical-align: middle;
    }
  }
}
