@import "~@/assets/scss/global.scss";








































































































































































































































































































































































































.page-top {
    padding: 6px 14px 0 14px;
}

.size-container {
    text-align: left;
    white-space: break-spaces;

    .size-tag:first-child {
        margin-left: 10px;
    }

    .size-tag {
        font-size: 14px;
        height: 30px;
        margin-bottom: 5px;

        .out-stock {
            width: 50px;

            ::v-deep {
                .el-input__inner {
                    height: 25px !important;
                    vertical-align: middle;
                }
            }
        }
    }
}

::v-deep {
    .el-checkbox {
        padding: 2px 5px !important;
        height: 20px !important;
        line-height: 13px !important;
        margin-right: 2px !important;

        &__label {
            padding-left: 2px !important;
        }
    }
}

.statistics {
    border-top: 1px solid #e9edf1;
    margin-top: 5px;
    width: 100%;

    div {
        display: inline-block;
        height: 120px;
        width: 400px;
        line-height: 120px;
        font-size: 18px;
        text-align: center;
    }
}

.payment-tag {
    width: 100px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 13.3px;
}
