@import "~@/assets/scss/global.scss";





























































































































































































































































































































.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.div-spec-info {
    padding: 10px 0px 0px 7px;

    .div-check-item {
        margin-top: 5px;
        margin-left: 15px;
        display: flex;

        .el-checkbox {
            margin-right: 10px;
        }

        .div-check-item-color {
            float: left;
            width: 20%;
            overflow: hidden;
        }

        .div-check-item-size {
            float: left;
            width: 80%;
            overflow: hidden;
        }
    }
}
