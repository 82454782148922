@import "~@/assets/scss/global.scss";























































































































































































































































































































































































































































































































































































.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }

    .el-button {
        width: 100px !important;
        padding: 10px 10px !important;
    }
}

.div_prop_item {
    display: inline-block;
}

.div_prop_value {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 9px;
    margin-right: 4.5px;
    margin-left: 4.5px;
    padding: 2px;
    font-size: 14px;
    line-height: 14px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    color: #333;
    text-overflow: ellipsis;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, .06);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    position: relative;
}

.div_prop_value.current {
    color: #409EFF;
    border-color: #409EFF;
    background-color: #fff7f3;
}

.div_prop_value.disabled {
    cursor: not-allowed;
    color: #ccc;
    background-color: rgba(0, 0, 0, .06);
}

.time {
    color: #F56C6C;
    font-size: 25px;
}

.editForm {
    .el-input {
        max-width: 140px;
    }

    ::v-deep {
        .el-form-item__label {
            padding-right: 0px;
        }
    }

    .el-radio {
        margin-left: 10px;
        margin-right: 0px;
    }
}
