@import "~@/assets/scss/global.scss";




























































.tree-wrapper {
  border: 1px solid #0075e3;

  .tree-title {
    height: 30px;
    background: #0075e3;
    font-size: 14px;
    line-height: 30px;
    color: white;

    i {
      font-size: 19px;
      margin: 0 5px;
      vertical-align: middle;
    }
  }
}
