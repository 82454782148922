@import "~@/assets/scss/global.scss";



































































































































































































































































































































::v-deep {
  .el-header {

    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    ::v-deep {

      .el-form-item__label {
        padding-right: 0;
      }
    }

    .div-search-left {
      width: 600px;
      float: left;
      margin-top: 5px;
      margin-left: 5px;
    }

    .div-batch-set {
      float: left;
      text-align: center;
      margin-left: 630px;
      margin-top: 24px;

      .div-info {
        color: #aaa;
        font-size: 12px;
      }
    }

    .div-batch-button {
      float: right;
      margin-top: 40px;
      margin-right: 30px;
    }
  }

  .el-main {
    .el-table__header th {
      background-color: #f3f7f9 !important;
      border-color: #ebeef4 !important;
    }

    .red {
      color: red;
    }
  }
}
