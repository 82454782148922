@import "~@/assets/scss/global.scss";




















































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-aside {
    .el-header {
      .el-form {
        .el-form-item {
          margin-bottom: 5px !important;
        }
      }

      .el-select {
        width: 100px;
        margin-right: 5px;
      }

      .date-range {
        width: 210px;

        .el-range-input {
          width: 70px;
        }
      }

      .el-radio {
        margin-right: 10px;
      }

      .el-form-item__label {
        padding-right: 0;
      }

    }
  }

  .el-main {
    .el-form {
      margin-left: 10px;

      .span-name {
        font-weight: bold;
        font-size: 20px;
        margin-right: 10px;
      }

      .el-form-item__label {
        padding-right: 0px !important;
        font-size: 16px;
      }

      .el-form-item__content {
        font-size: 16px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px;
      }

      .table-button {
        float: right;
        width: 60px;
        padding: 5px;

        .el-input__inner {
          padding: 0;
        }
      }
    }

    .num {
      margin-right: 5px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &>footer {
    .div-button-left {
      display: inline-block;
      width: 50%;
      margin-top: 5px;

      .el-button,
      .div-upload {
        float: left;
        height: 30px;
        margin-left: 5px;
      }
    }

    .div-button-right {
      display: inline-block;
      width: 50%;
      margin-top: 4px;

      .el-button {
        float: right;
        margin-left: 5px;
      }
    }
  }

}
