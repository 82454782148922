@import "~@/assets/scss/global.scss";



























































































































































































































































































































































































































































































.content-box {
  position: absolute;
  right: 0;
  left: 0;
  top: 30px;
  bottom: 70px;
  overflow: auto;
}

.content-title {
  margin: 10px 10px 0 10px;
  font-weight: bold;
  font-size: 16px;
}

.addnew-line {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px dashed gray;
  margin: 0 110px 0 10px;
}
