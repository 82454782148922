@import "~@/assets/scss/global.scss";































































@mixin status {
    height: 60px;
    width: 60px;
    border: 6px solid;
    border-radius: 40px;
    color: white;
    text-align: center;
    line-height: 60px;
    position: absolute;
    font-size: 55px;
    font-weight: 900;
    top: 0;
    left: 50%;
    margin-left: calc(-60px / 2);
    margin-top: calc(-60px / 2);
}

@mixin title {
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 20px 10px;
}

.authorize {
    height: 200px;
    width: 400px;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: calc(-400px / 2);
    margin-top: calc(-350px / 2);
    border-radius: 8px;

    .success-status {
        @include status;
        border-color: #e7f3fe;
        background: #0178e6;
    }

    .error-status {
        @include status;
        border-color: #ffe8e8;
        background: #f96868;
    }

    .content {
        width: 100%;
        height: 165px;
        position: absolute;
        bottom: 0;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;

        .success-title {
            @include title;
            color: #0075e3;
        }

        .error-title {
            @include title;
        }

        .msg {
            margin-left: 10px;
        }
    }
}
