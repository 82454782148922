@import "~@/assets/scss/global.scss";




























































































































































































































































































































































::v-deep {
  .red {
    color: red;
  }

  .span-name {
    font-weight: bold;
    font-size: 20px;
  }

  .btn_download {
    float: right;
    margin-right: 10px;
  }

  .btn_add_detail {
    margin-top: 7px;
  }
}
