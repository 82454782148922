@import "~@/assets/scss/global.scss";







































































































































.page-container {
  margin: 0px auto;
  padding: 10px 10px 0px 10px;
}

.main-content>div {
  height: auto !important;
}

.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-right: 50px;
}

.footer {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  .el-button {
    width: 120px;
  }
}

.div_qr_code_attr {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}

.avatar-uploader {
  width: 400px;

  ::v-deep {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 398px;
      height: 92px;
      margin-bottom: -10px;

      .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
        width: 398px;
        height: 92px;
        line-height: 92px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
    }

    .el-upload:hover {
      border-color: #409EFF;
    }


  }
}
