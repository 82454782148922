@import "~@/assets/scss/global.scss";





























































































































































































































































































































































































































































































::v-deep {
  .red {
    color: red;
  }

  .el-aside {
    .el-header {
      .el-form {
        .el-form-item {
          margin-bottom: 5px !important;
        }
      }

      .el-select {
        width: 100px;
        margin-right: 5px;
      }

      .el-form-item__label {
        padding-right: 0;
      }
    }
  }

  .el-table {

    .is-deleted {
      color: red;
      text-decoration: line-through;

      .cell {
        color: #b5b5b5;
      }
    }
  }

  .span_wname {
    color: #9e9e9e;
    font-size: 12px;
  }

  .div_detail_title {
    font-weight: normal;
    font-size: 24px;
    line-height: 42px;
  }

  .div_detail_name {
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    margin-right: 10px;
  }

  .div_detail_all {
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    margin: 10px 0 0 10px;

    .span_detail_tip {
      color: #9e9e9e;
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .div_detail_button {
    margin-left: 10px;
  }

  .el-main {
    border-top: 0px !important;
  }
}
