@import "~@/assets/scss/global.scss";
























































































































































































































.prop-value {
  padding: 5px 18px;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 0 10px 10px 0;
  cursor: pointer;

  &.selected {
    position: relative;
    border-color: #0075e3;

    &::before {
      content: "";
      position: absolute;
      right: -1px;
      bottom: -1px;
      border: 10px solid #0075e3;
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &::after {
      content: "";
      width: 4px;
      height: 8px;
      position: absolute;
      right: 1px;
      bottom: 3px;
      border: 2px solid #fff;
      border-top-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg);
    }
  }
}
