@import "~@/assets/scss/global.scss";











































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      .el-range-input {
        width: 70px;
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .div-setspeced {
    color: green;
    font-size: 14px;
  }

  .detail {
    .el-header {
      color: #000000;
      margin-bottom: 10px;
      height: 32px;
      line-height: 32px;
      vertical-align: middle;

      .span-name {
        font-weight: bold;
        font-size: 20px;
      }

      .span-total,
      .span-spec-count {
        margin-left: 20px;
        font-size: 16px;
      }

      .span-batch-num {
        float: right;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        margin-top: 15px;
      }

      .span-delete:hover,
      .div-wait-setspec:hover {
        text-decoration: underline;
      }

      .div-wait-setspec {
        color: red;
        font-size: 14px;
        cursor: pointer;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px;
      }

      .table-button {
        float: right;
        width: 60px;
        padding: 5px;

        .el-input__inner {
          padding: 0;
        }
      }
    }

    .el-footer {
      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 50%;
        margin-top: 5px;

        &:last-child {
          text-align: right;

          .div-upload,
          .el-button {
            margin-left: 5px;
          }
        }
      }
    }

  }

  .page-status {
    position: fixed;
    top: 100px;
    right: -42px;
    z-index: 10000;

    &:hover {
      right: 0;
    }

    div {
      border: 1px solid #409eff;
      border-radius: 20px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 3px 3px 3px 5px;
      cursor: pointer;
      background: #fff;

      height: 25px;
      line-height: 25px;
      font-size: 16px;
      width: 60px;
      padding-left: 15px;
      font-weight: bold;
      color: #409eff;
      cursor: pointer;

      &.is-active {
        background: #dbedff;
      }
    }

    div:not(:first-child) {
      border-top: none;
    }

    div.on {
      background-color: #409eff;
      color: #ffffff;
    }

  }
}
