@import "~@/assets/scss/global.scss";
































































































.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.ProPrice {
    ::v-deep {
        .el-form-item__label {
            color: #F56C6C;
        }
    }
}
