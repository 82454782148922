@import "~@/assets/scss/global.scss";

























.wrapper {
  height: 100%;
  width: 100%;
  .error {
    width: 100%;
    padding-top: 15%;
    text-align: center;
    :first-child {
      font-family: fangsong;
      font-size: 120px;
      font-weight: 900;
      color: #fff;
    }
    :last-child {
      font-size: 25px;
      color: #ccc;
    }
  }
}
