@import "~@/assets/scss/global.scss";





































































































































































































.eidt-item {
  float: left;
}

.edit-content {
  width: 68%;
}

.edit-field {
  width: 10%;
  border: 1px solid #ccc;
  height: 600px;
  overflow-y: auto;

  .title {
    background: #edf2fa;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }

  .field-item {
    height: 30px;
    border-bottom: 1px solid #dbe8ff;
    line-height: 30px;
    margin: 4px;
    padding-left: 5px;
  }
}

.edit-template {
  width: 20%;
  height: 600px;
  border: 1px solid #ccc;
  padding-top: 10px;

  .tip{
    border-top: 1px solid #ddd;
    color: #7189F7;
    padding: 10px;
  }
}

.save-block {
  text-align: right;
  clear: both;
  padding: 10px 20px 0 0;

  .el-button {
    width: 150px;
  }
}
