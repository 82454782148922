@import "~@/assets/scss/global.scss";

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {

  .el-input-number--small {
    width: inherit !important;
  }

  .table_btn_right {
    position: absolute;
    right: 3px;
  }

  .el-button--mini {
    padding: 7px 5px !important;
  }

  .el-table .cell.el-tooltip {
    white-space: break-spaces !important;
  }

  .green {
    color: green;
  }

  .gray {
    color: gray;
  }

  .red {
    color: red;
  }

  .blue {
    color: blue;
  }

  .div_edit_column {
    float: left;
    width: 280px;

    .edit_row_item {
      width: 280px;
      margin-left: 10px;
      font-size: 15px;
      margin-top: 5px;
    }

    .edit_row_item_title {
      width: 78px;
      text-align: right;
      display: inline-block;
    }

    .edit_row_item_note_title {
      width: 48px;
      text-align: right;
      vertical-align: top;
      display: inline-block;
    }

    .edit_row_item_note {
      margin-left: 10px;
      font-size: 15px;
      margin-top: 5px;
    }
  }

  .div_edit_column:last-child {
    width: inherit !important;
  }

  .div_edit_row {
    margin-top: 5px;


    .edit_row_code {
      font-weight: bold;
      font-size: 20px;
    }

    .edit_row_total {
      margin-left: 15px;
      font-size: 16px;
    }

  }

  .div_edit_row:first-child {
    margin-top: 0px;
  }

  .pointed {
    color: #409EFF !important;
    margin: 0 5px;
    font-size: 16px;
  }


  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .el-main {
    .el-header {

      .btn-set-num {
        float: right;
        margin-right: 225px;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-copy {
        color: green;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-delete:hover {
        text-decoration: underline;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px !important;
      }

      .table-button {
        float: right;
        width: 60px !important;
        padding: 5px !important;

        .el-input__inner {
          padding: 0 !important;
        }
      }
    }

    .el-footer {
      vertical-align: middle;

      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 50%;

        &:last-child {
          text-align: right;
          width: 50%;

          .div-upload,
          .el-button {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}


#print-view {
  position: fixed;
  left: -1000px;
}
