@import "~@/assets/scss/global.scss";




























































































































































.tooltip-item {
    margin: 4px;
}

::v-deep {

    .el-table__header th {
        background-color: #f3f7f9 !important;
        border-color: #ebeef4 !important;
    }
}
