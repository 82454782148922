@import "~@/assets/scss/global.scss";




















































.title {
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
  line-height: 40px;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  cursor: pointer;
  height: 40px;
  color: #555;
  display: block;
  font-size: 12px;
  text-decoration: none;
  padding-left: 25px;
  box-sizing: border-box;
  border-left: 2px solid #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title:hover {
  color: blue;
}

.active {
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
  line-height: 40px;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  cursor: pointer;
  height: 40px;
  color: #555;
  display: block;
  font-size: 12px;
  text-decoration: none;
  padding-left: 25px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ecf4ff;
  border-left: 2px solid #1979ff;
}
