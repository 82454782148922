@import "~@/assets/scss/global.scss";














































































































































































































































































































































































































































































@mixin trade-icon {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  opacity: 0.9;

  img {
    height: 16px;
    width: 16px;
  }
}

.cash-register-main {
  min-width: 1760px;

  .trade-main {
    height: 100%;
    border-radius: 7px;

    &>header {
      position: relative;

      .el-form {
        position: absolute;
        left: 230px;
        top: 0;
        width: calc(100% - 230px);

        .el-select {
          width: 100px;
          margin-right: 5px;
        }

        .date-range {
          width: 210px;

          ::v-deep {
            .el-range-input {
              width: 70px;
            }
          }
        }

        .search-item {
          width: 85px !important;
        }

        .barcode {
          width: 45px !important;
          margin-right: 5px;

          &.encrypt {
            width: 200px !important;
            max-width: 250px;
          }
        }

        .el-button {
          width: 68px;
        }

        ::v-deep {
          .el-range-editor {
            &.el-input__inner {
              padding: 3px 0 0 5px;
            }

            .el-range-separator {
              width: auto;
            }

            .el-range__close-icon {
              width: 5px;
            }
          }

          .el-form-item__label {
            padding-right: 0;
          }

          .el-form-item {
            margin-right: 8px;
          }

          .el-input__inner {
            padding: 3px 0 0 5px;
          }
        }
      }

      ::v-deep {
        .el-radio-button__inner {
          font-size: 14px;
        }
      }
    }

    .trade-list {
      height: 100%;
      overflow-y: auto;
      position: relative;

      .trade {
        display: inline-block;
        width: 120px;
        height: 105px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        color: #303133;
        cursor: pointer;
        background-color: #f3f7f9;
        border: solid 2px #d2dadf;
        position: relative;
        overflow: hidden;
        margin: 0 5px 5px 0;

        &.is-active {
          background-color: #e9f4ff;
          border-color: #409eff;
        }

        &.multi {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 1px;
            border-right: solid 1px #d2dadf;
            border-bottom: solid 1px #d2dadf;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 4px;
            border-right: solid 1px #d2dadf;
            border-bottom: solid 1px #d2dadf;
          }
        }

        ::v-deep {

          .el-card__header,
          .el-card__body {
            padding: 5px;
            position: relative;

            &>div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;
              min-height: 20px;
            }
          }
        }

        .purchaser {
          text-align: center;

          &.out-stock-num {
            color: #f44a4a;
          }

          &::before {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0px;
            left: 0px;
            width: 20px;
            height: 20px;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
          }

          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0px;
            right: 0px;
            width: 20px;
            height: 20px;
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
          }

          &.proxy {
            &::before {
              content: '代';
              color: #fff;
              background-color: #0075E3;
              width: 22px;
              height: 22px;
            }
          }

          &.split {
            &::before {
              background-image: url('/static/img/part.png');
            }
          }

          &.refund {
            &::before {
              background-image: url('/static/img/refund.png');
            }
          }

          &.credit {
            &::after {
              background-color: #f44a4a;
              background-image: url('/static/img/credit.png');
            }
          }

          &.alipay {
            &::after {
              background-color: #409eff;
              background-image: url('/static/img/alipay.png');
            }
          }

          &.wechat {
            &::after {
              background-color: #67c23a;
              background-image: url('/static/img/wechat.png');
            }
          }

          &.cash {
            &::after {
              background-color: #e6a23c;
              background-image: url('/static/img/cash.png');
            }
          }

        }

        .create-time {
          font-weight: normal;
        }
      }

      .trade.is-active {
        background-color: #e9f4ff;
        border-color: #409eff;
      }
    }

    &>footer {
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;

      &>div {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 10px;
      }

      &>span {
        margin-left: 20px;
        display: inline-flex;
        vertical-align: middle;
      }

      &>i {
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }

  main {
    position: relative;
    overflow-x: hidden;

    .pay-type {
      position: absolute;
      top: 90px;
      right: -72px;

      &:hover {
        right: 0px;
      }

      div {
        border: 1px solid #409eff;
        border-radius: 20px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 3px 3px 3px 5px;
        cursor: pointer;
        background: #fff;

        &.is-active {
          background: #dbedff;
        }
      }

      div:not(:first-child) {
        border-top: none;
      }

      span {
        height: 25px;
        width: 25px;
        display: inline-block;
        border-radius: 15px;
        vertical-align: middle;
        text-align: center;
        margin-right: 3px;

        &.wechat {
          background: #67c23a;
        }

        &.alipay {
          background: #409eff;
        }

        &.cash {
          background: #e6a23c;
        }

        &.credit {
          background: #f44a4a;
        }

        &.part-take {
          border: 1px solid #6c57f0;
        }

        &.refund {
          border: 1px solid #f44a4a;
        }
      }

      img {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-top: 3px;
      }
    }

  }

}
