@import "~@/assets/scss/global.scss";




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































#detail-main {
    ::v-deep {
        .el-input__inner {
            text-align: center;
        }
    }
}

.detail-main {
    height: 100%;
    border-radius: 7px;
    position: relative;
    overflow-y: hidden;

    .proxy-table {
        height: 100%;
    }

    ::v-deep {
        .detail-row {
            cursor: pointer;

            td {
                padding: 5px 0;
            }

            .el-icon-edit-outline {
                color: #409eff !important;
            }

            .el-icon-delete {
                color: #ff0000 !important;
            }

            &.refund {
                .cell {
                    color: #ff0000 !important;
                }
            }

            &.is-active {
                td {
                    background: #e9f4ff !important;
                }
            }

            &.is-delete,
            &.is-hidden {
                display: none;
            }
        }
    }

    .operate1 {
        box-sizing: border-box;
        text-align: right;
        position: absolute;
        top: 0px;
        right: 2px;
    }

    .statistics {
        background-color: #d5eaff !important;

        &>main,
        &>aside {
            padding: 0 5px;

            &:first-of-type {
                &>div {
                    text-align: left;
                }
            }

            &>div {
                line-height: 34px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &>span {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 31px;
                }
            }
        }

        &>main {
            &>div {
                line-height: 32px;
            }
        }

        .money {
            color: red;
        }

        .purchaser {
            font-size: 30px;
        }
    }

    .dept {
        background-color: #abd4fd !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 15px;
            border: 0;
            line-height: 24px;
        }

        .title.all {
            font-weight: bold;
            font-size: 18px;
            color: black;
        }

        .money {
            color: #0075e3;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 57px;

            &.all {
                font-size: 24px;
                line-height: 24px;
                color: red;
                border: 0;
            }
        }


        .desc {
            color: #8d8a90;
            font-size: 10px;
            max-width: 55%;

            &>div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .remark {

        &>main,
        &>aside {
            border: 0;
        }

        ::v-deep {
            .el-input-group__prepend {
                width: 30px;
            }
        }

    }

    .operate2 {
        .receive {

            ::v-deep {
                .el-input-group__prepend {
                    width: 30px;
                }

                .el-input__inner {
                    height: 120px;
                    color: red;
                    font-size: 45px;
                    font-weight: bold;
                }

                .el-input-group__prepend {
                    font-size: 30px;
                    color: black;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }

        .el-row {
            .el-col {
                height: 120px;
                position: relative;

                .el-tag {
                    margin-left: 0;
                    margin-bottom: 5px;
                    width: calc(100%);
                    height: calc(50% - 2.5px);
                    padding: 15px 0;
                    font-weight: bold;
                    font-size: 16px;
                    cursor: pointer;
                    text-align: center;

                    &--danger {
                        margin-left: 5px;
                        width: calc(60% - 2.5px);
                    }

                    &--warning {
                        margin-left: 5px;
                        width: calc(50% - 2.5px);
                    }

                    &--dark {
                        position: relative;

                        &::before {
                            content: "\e6da";
                            font-family: element-icons !important;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                }

                .el-button {
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 5px;
                    margin-left: 0;
                    width: calc(100%);
                    height: calc(50% - 2.5px);
                    padding: 0;
                    font-weight: bold;
                    font-size: 20px;

                    &--default {
                        width: calc(40% - 2.5px);
                    }

                    &--info,
                    &--warning.is-plain {
                        width: calc(50% - 2.5px);
                        font-weight: normal;
                        font-size: 10px;
                    }

                    &--warning.is-plain {
                        margin-left: 5px;
                    }
                }

                .delivery {
                    width: calc(100%);
                    height: calc(100%);
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }

        &>div {
            vertical-align: top;
        }


        .box1 {
            vertical-align: top;
            width: calc(61.5%);
            display: inline-block;
        }

        .box1_1 {
            width: calc(79.5%);
        }

        .box2 {
            display: inline-block;
            height: 120px;
            width: calc(36%);

            .el-button {
                vertical-align: top;
                margin-bottom: 5px;
                margin-left: 0px;
                width: calc(100%);
                max-width: 101px;
                height: calc(50% - 5px);
                font-weight: bold;
                font-size: 20px;
            }

            &>div {
                vertical-align: top;
                display: inline-block;
                width: calc(50% - 5px);
                padding-left: 5px;
                height: 100%;

                ::v-deep {
                    .el-button--info.is-plain {
                        width: calc(50% - 2.5px);
                        line-height: 20px;
                        margin-right: 2.5px;
                        padding: 0px;
                        font-weight: normal;
                        font-size: 10px;
                    }

                    .el-button--warning.is-plain {
                        width: calc(50% - 2.5px);
                        margin-left: 2.5px;
                        line-height: 20px;
                        padding: 0px;
                        font-weight: normal;
                        font-size: 10px;
                    }
                }
            }

            .repay {
                height: calc(100% - 5px);
                font-size: 30px;
            }
        }
    }

    #print-view {
        position: fixed;
        left: -1000px;
    }

    .stock-title {
        width: calc(100% - 40px);

        &>div {
            display: inline-block;
            min-width: 25%;
        }

        .item-title {
            text-align: center;
            width: calc(50%);

            .point {
                font-size: 20px;
                font-family: 'fangsong', Courier, monospace;
                font-weight: bold;
            }
        }
    }

    ::v-deep {

        .dlg_btn {
            width: 80px;
            padding: 10px 10px;
        }

        .el-dialog__body {
            .money {
                font-size: 18px;
                font-family: 'fangsong', Courier, monospace;
                font-weight: bold;
                color: red;
            }

            .el-form {
                padding-right: 0px;
            }

            .el-form-item {

                .el-input {
                    max-width: 150px;
                }

                .el-date-editor--date {
                    width: 150px !important;
                }

                .el-button {
                    margin-left: 2px;
                }

                .el-form-item__label {
                    padding: 0 2px;
                }
            }

            .out-stock {

                .el-input {
                    width: 40px;
                    vertical-align: top;

                    .el-input__inner {
                        padding: 0;
                        text-align: center;
                    }
                }

                .el-tag--medium {
                    height: 42px;
                    line-height: 42px;
                    font-size: 15px;
                    margin: 0 10px 5px 0;
                    width: calc(25% - 10px);

                    &>span {
                        display: inline-block;
                        width: calc(100% - 33px);
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow-x: hidden;
                    }
                }
            }

        }
    }
}
