@import "~@/assets/scss/global.scss";

































































































































































































































































































































































































































































































































































































































































































































::v-deep {

  .cell:has(.div_create_time) {
    padding-left:3px;
    padding-right:3px;
 }

  .div_create_time {
    i {
      width: 16px;
      height: 16px;
      background-image: url(/static/img/received.png);
      margin-top: 3px;
      float: left;
    }
  }

  .div_print_time {
    i {
      width: 16px;
      height: 16px;
      background-image: url(/static/img/printer.png);
      margin-top: 3px;
      float: left;
    }
  }
}

#allocation-content {
  min-width: 1760px;

  #trade {

    .search {
      display: inline-block;
      margin-right: 5px;
      position: relative;

      .el-range-editor {
        width: 200px;
        padding: 3px 5px;

        ::v-deep {
          .el-range__close-icon {
            width: 5px;
          }
        }
      }

      .el-select:nth-child(1) {
        width: 90px;
        margin-right: 5px;
      }

      .el-select:nth-child(2),
      .el-select:nth-child(3) {
        width: 170px;

        ::v-deep {
          .el-tag:first-child {
            display: inline-block;
            max-width: 59px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .el-tab:last-child {
            margin-left: 3px;
          }
        }
      }

      .el-input {
        width: 170px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    ::v-deep {
      .el-footer {
        font-size: 15px;
        font-weight: bold;

        span {
          display: inline-block;
          width: 33%;
          line-height: 40px;
          vertical-align: middle;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .proxy-status {
      &::before {
        content: "代";
        height: 20px;
        width: 20px;
        position: absolute;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        color: #ffffff;
        background: #0075E3;
        border-bottom-right-radius: 15px;
        padding: 0;
        font-size: 10px;
        z-index: 50;
      }
    }
  }

  #print-view {
    ::v-deep {
      .el-main {
        max-width: 260mm;
        height: 100%;
        background: #a3a3a3;
        padding: 0;
        position: relative;
        box-sizing: border-box;
        overflow: auto;

        .paper-wrapper {
          min-height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .el-footer {
        &>span {
          line-height: 40px;
          vertical-align: middle;
        }

        >span:first-child {
          .el-input {
            width: 50px;
            margin: 0 5px;

            .el-input__inner {
              text-align: center;
            }
          }
        }

        >span:last-child {
          float: right;

          .el-button {
            width: 100px;
          }
        }
      }
    }

    .print-config {
      position: absolute;
      top: 3px;
      right: 0;
    }

    .print-config-eidt {
      .el-row {
        margin-bottom: 8px;
      }

      .edit-title {
        text-align: right;
        line-height: 18px;

        &.edit-title-big {
          line-height: 32px;
        }
      }

      ::v-deep {
        .el-tabs__item {
          color: #606266 !important;

          &:first-child {
            padding-left: 32px;
          }

          &.is-active {
            color: #409eff !important;
          }
        }
      }

      .el-input {
        width: 80px;
        margin: 0 5px;
      }
    }
  }

  main {
    position: relative;

    .print-view-left {
      position: absolute;
      top: 80px;
      right: 0;
      z-index: 99;

      >div {
        background: white;
        margin-bottom: 5px;
        height: 32px;
        width: 65px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #a3a3a3;
        border-right: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        cursor: pointer;

        &.is-active {
          color: #409eff;
          border-color: #409eff;
        }
      }
    }
  }
}
