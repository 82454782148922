@import "~@/assets/scss/global.scss";






























































































::v-deep {
  .el-main {
    background-color: #e9edf1 !important;
  }

  .el-divider__text {
    font-size: 20px;
    font-weight: bold;
  }
}

.editForm {
  margin-top: 20px;
  margin-right: 150px;
}

.footer {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  .el-button {
    width: 120px;
  }
}
