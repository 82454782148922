@import "~@/assets/scss/global.scss";





































































































































































































































































































































































































































































































































::v-deep {
    .table_btn_right {
        position: absolute;
        right: 3px;
    }

    .table_btn_left {
        position: absolute;
        left: 3px;
    }

    .table_btn_left,
    .table_btn_right .el-button--small {
        padding: 5px 5px !important;
        margin-top: 4px;
    }

    .div-setspeced {
        color: green;
        font-size: 14px;
    }

    .div-wait-setspec:hover {
        text-decoration: underline;
    }

    .div-wait-setspec {
        color: red;
        font-size: 14px;
        cursor: pointer;
    }

    .span_stock {
        color: red;
    }

    .edit_input .el-input__inner {
        height: 50px !important;
        font-size: 40px !important;
    }

    .pointed {
        color: #409EFF !important;
        margin: 0 5px;
        font-size: 16px;
    }

    .num {
        margin: 0 2px;
        font-size: 16px;
        font-weight: bold;
    }
}
