@import "~@/assets/scss/global.scss";
































































































































































































































































































































































































































































































































































































































































































.stall-trade-table {
    overflow-y: auto;

    img {
        vertical-align: middle;
        padding-left: 15px;
    }

    .bold-info {
        font-weight: bold;
        font-size: 18px;
        color: #409EFF;
    }

    .span_inline{
        text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
        display: inline-flex;
        max-width: 250px;
    }

    .bold-info.proxy {
        color: #ff6000 !important;
    }

    .bold-info.money {
        color: #F56C6C !important;
    }

    .hand {
        cursor: pointer;
    }

    .el-icon-warning {
        color: #F56C6C !important;
    }

    .size-tag:first-child {
        margin-left: 10px;
    }

    .size-tag {
        font-size: 14px;

        .out-stock {
            width: 50px;

            ::v-deep {
                .el-input__inner {
                    height: 25px !important;
                    vertical-align: middle;
                    padding: 0px 5px;
                }
            }
        }
    }

    .middleTable {
        border: 1px solid #a7a7a7 !important;
        border-right: 0 !important;

        ::v-deep {
            .el-table__cell {
                border-color: #a7a7a7 !important;
            }

            .el-table__cell {
                border-right: 1px solid;
            }

            .el-input-group__append {
                padding: 0 8px !important;
            }
        }
    }

    ::v-deep {
        .el-collapse-item__content {
            padding-bottom: 0;
        }

        .el-collapse-item__wrap {
            border-bottom: 2px dashed #a7a7a7;
        }

        .el-card__header {
            padding: 10px 20px;
            position: relative;

            &>div {
                &>div {
                    display: inline-block;
                    vertical-align: top;
                    line-height: 30px;
                }
            }

            .el-button {
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        .el-card__body {
            padding: 0;
        }

        .require-tag {
            &::before {
                content: "新";
                height: 15px;
                width: 15px;
                position: absolute;
                top: 2px;
                right: 2px;
                color: #6ab319;
                border-radius: 15px;
                border: solid 1px #6ab319;
                padding: 0 0 1px 0;
                font-size: 10px;
                z-index: 50;
            }
        }
    }
}
