@import "~@/assets/scss/global.scss";















































































































































































































































































































































































































































































































.cash-register-main {
  min-width: 1760px !important;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;

  .purchaser-info {
    .search-fee {
      width: 70px !important;
    }

    .search-name {
      width: 100px !important;
    }

    ::v-deep {
      .el-form-item {
        margin-right: 0;
      }
    }
  }

  ::v-deep {
    .el-form-item {
      .el-select {
        width: 90px;
      }

      .el-input {
        max-width: 150px;
      }

      .el-date-editor--date {
        width: 150px !important;
      }

      .el-date-editor--daterange {
        width: 210px;

        .el-range__close-icon {
          width: 5px;
        }
      }

      .el-button {
        margin-left: 2px;
      }

      .el-form-item__label {
        padding: 0 2px;
      }
    }

    .el-table {
      .is-selected {
        background-color: rgb(224, 239, 255);
      }

      .is-selected:hover>td {
        background-color: rgb(224, 239, 255);
      }

      .is-deleted {
        color: red;
        text-decoration: line-through;

        .cell {
          color: #b5b5b5;
        }
      }
    }

    .el-table--fit::before {
      height: 0 !important;
    }
  }

  .statistics {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;

    &>div {
      width: 33%;
      display: inline-block;
      text-align: center;
    }
  }

  .record-list {
    .statistics {
      &>div {
        width: 25% !important;
      }
    }

    ::v-deep {
      .el-table {
        .el-table-column--selection {
          .cell {
            padding: 0 5px !important;
          }
        }
      }
    }
  }

  ::v-deep {
    .el-dialog__body {
      .money {
        font-size: 18px;
        font-family: 'fangsong', Courier, monospace;
        font-weight: bold;
        color: red;
      }

      .el-form {
        padding-right: 0px;
      }

      .el-form-item {
        .el-input {
          max-width: 150px;
        }

        .el-date-editor--date {
          width: 150px !important;
        }

        .el-button {
          margin-left: 2px;
        }

        .el-form-item__label {
          padding: 0 2px;
        }
      }
    }
  }
}
