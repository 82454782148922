@import "~@/assets/scss/global.scss";























































































































































































.editForm {
    border: 1px solid #ebeef5;
    padding-top: 10px;
    margin-bottom: 10px;
}

.operation {
    border: 1px solid #ebeef5;
    padding: 5px;
    margin-bottom: 10px;

    > div {
        display: inline-block;
        margin: 0 10px;
    }
}

.size-container {
    text-align: left;
    white-space: break-spaces;

    .size-tag:first-child {
        margin-left: 10px;
    }

    .size-tag {
        font-size: 14px;
        height: 30px;
        margin-bottom: 5px;

        .out-stock {
            width: 50px;

            ::v-deep {
                .el-input__inner {
                    height: 25px !important;
                    vertical-align: middle;
                }
            }
        }
    }
}
