@import "~@/assets/scss/global.scss";











































































































































































































































































































































.home {
  width: 1750px;

  .el-card {
    margin: 0px 10px 10px 0px;
  }

  .card-title {
    height: 30px;
    font-size: 15px;
    font-weight: 500;

    i {
      margin-right: 3px;
      color: #3975df;
    }
  }

  >div {
    display: inline-block;
    vertical-align: top;

    .entrance {
      display: inline-block;

      .el-card {
        width: 900px;
        height: 288px;

        .entrance-xiebutou {
          position: relative;
          width: 430px;
          height: 250px;
          display: inline-block;

          .xiebutou {
            width: 400px;
            height: 288px;
            object-fit: cover;
            position: relative;
            top: -20px;
            left: -20px;
          }

          .el-button {
            position: absolute;
            top: 210px;
            left: 0;
            height: 40px;
            width: 370px;
            font-size: 16px;
            border-radius: 20px;
          }
        }

        .entrance-item {
          display: inline-block;
          margin-left: 20px;
          vertical-align: top;
          height: 250px;
          width: 118px;
          line-height: 22px;
          text-align: center;
          color: #78838e;

          .entrance-item-en-title {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #b3b8bc;
          }

          img {
            height: 90px;
            width: 90px;
            object-fit: contain;
            display: block;
            border: 1px #ccc dashed;
            margin: 10px auto;
          }

          .entrance-item-title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }

          .entrance-item-desc {
            font-size: 12px;
            font-weight: 400;
            color: #78838e;
          }
        }
      }
    }

    .order-count {
      display: inline-block;

      .el-card {
        width: 480px;
        height: 200px;
      }

      .count-item {
        width: 110px;
      }
    }

    .stall-count {
      display: inline-block;

      .el-card {
        width: 410px;
        height: 200px;
      }

      .count-item {
        width: 123px;
      }
    }

    .count-item {
      display: inline-block;
      height: 149px;
      cursor: pointer;

      text-align: center;

      :first-child {
        margin-top: 36px;
        font-size: 26px;
        font-weight: 600;
      }

      :last-child {
        font-size: 14px;
        font-weight: 400;
      }

      &:hover {
        color: #0075e3;
        box-shadow: 0px 0px 6px 0px rgba(180, 189, 198, 0.8);
      }
    }

    .fast-link {
      display: inline-block;

      .el-card {
        width: 400px;
        height: 500px;
      }

      .fast-link-item {
        color: black;
        height: 120px;
        width: 120px;
        display: inline-block;
        text-align: center;
        margin: 12px 0;
        cursor: pointer;

        &:hover {
          color: #409eff;
          box-shadow: 0px 0px 6px 0px rgba(180, 189, 198, 0.8);
          border-radius: 4px;
        }

        img {
          height: 86px;
          width: 86px;
          object-fit: contain;
          display: block;
          padding: 5px 0 0 15px;
        }
      }
    }

    .sale-trend {
      .el-card {
        width: 1314px;
        height: 404px;
      }
    }

    .notice {
      width: 412px;
      height: 288px;

      .notice-content {
        list-style: none;
        padding: 0;

        li {
          height: 30px;
          line-height: 30px;
          border-bottom: 1px dashed #dfe2e6;
          cursor: pointer;

          &:hover {
            color: #004cbd;
          }

          span {
            display: inline-block;
          }

          .notice-title {
            width: 270px;
          }

          .notice-time {
            width: 75px;
            margin-left: 20px;
          }
        }
      }
    }

    .ads {
      margin-bottom: 10px;

      img {
        width: 412px;
        height: 198px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  #newbie-guide-top {
    >div:first-child {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  #newbie-guide {
    margin-top: 10px;

    .newbie-guide-item {
      display: inline-block;
      width: 320px;
      height: 100px;
      cursor: pointer;
      box-sizing: border-box;
      margin-right: 20px;
      border: 1px solid #0075e3;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      font-size: 12px;

      &:hover {
        border-width: 2px;
        box-shadow: 0px 0px 16px 0px #0075e3;
      }

      .newbie-guide-item-top {
        font-size: 20px;
        color: #0075e3;
      }

      .newbie-guide-item-bottom {
        margin-top: 15px;
      }
    }

    .newbie-guide-item:last-child {
      margin-right: 0;
    }
  }

  .guide-container {
    ::v-deep {
      .el-tabs__item {
        text-align: left;
        max-width: 250px;
        display: block;
        height: auto;
        word-break: break-all;
        white-space: break-spaces;
        line-height: 20px;
        padding: 15px 15px 15px 0;
        font-size: 15px;
      }
    }
  }
}
